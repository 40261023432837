<template>
  <v-container>
    <h1 v-html="$t('search_label_orders_title')"></h1>
    <v-row class="row search spacer-md">
      <!-- Label Zugehörigkeit -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-label-affiliation ref="search-label-affiliation" @search="search" />
      </v-col>
      <!-- Kontakt-->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <!-- Milchverwerter -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <!-- Rechtliche Person -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" :loading="loading" @click="search"><span v-html="$t('search_label_orders_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('search_label_orders_new_search')" /></v-btn>
    <v-btn color="secondary" :loading="loadingDownload" @click="download"><span v-html="$t('search_label_orders_download')" /></v-btn>
    <v-btn color="secondary" v-if="showAddFirstLabel()" @click="addFirstLabelOrderForDairy()"
      ><span v-html="$t('search_label_orders_create_new_label')"
    /></v-btn>
    <v-data-table-virtual
      density="compact"
      :headers="fields"
      :items="items"
      class="zero-width"
      :fields="fields"
      :loading="loading"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          :to="{
            name: 'label_orders_edit',
            params: { dairyIdent: item.dairy.ident }
          }"
          class="tableaction link"
        >
          <v-icon color="#00A1DB">mdi-text-box-edit-outline</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.dairy.ident`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairy.id, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairy.ident }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairy.ident }}
        </template>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <template v-if="item.validFrom">
          {{ $formatDate(item.validFrom) }}
        </template>
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        <template v-if="item.validUntil">
          {{ $formatDate(item.validUntil) }}
        </template>
      </template>
    </v-data-table-virtual>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'
import _ from 'lodash'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchLabelAffiliation from '@/components/searchCards/searchLabelAffiliation.vue'
import { DTSHeader } from '@/services/BackendService'
import { useSearchLabelAffiliationStore } from '@/store/SearchLabelAffiliationStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'label_orders_read',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,
      loadingDownload: false,

      fields: <DTSHeader[]>[
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // MBH-ID
        {
          title: this.$t('label_orders_table_mbh_id'),
          key: 'dairy.ident',
          sortable: true
        },
        // Name / Firma
        {
          title: this.$t('search_label_orders_name_company'),
          key: 'dairy.name',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('search_label_orders_name_addiction'),
          key: 'dairy.formattedAddress',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('search_label_orders_zip'),
          key: 'dairy.zip',
          sortable: true
        },
        // Ort
        {
          title: this.$t('search_label_orders_localty'),
          key: 'dairy.locality',
          sortable: true
        },
        // Label
        {
          title: this.$t('search_label_orders_label_bundle'),
          key: 'labelBundle.name',
          sortable: true
        },
        //Von
        {
          title: this.$t('search_label_orders_from'),
          key: 'validFrom',
          sortable: true
        },
        //Bis
        {
          title: this.$t('search_label_orders_until'),
          key: 'validUntil',
          sortable: true
        }
      ],
      query: { page: 0, size: 10 }
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items)
      ]
    },
    queryParams() {
      return {
        ...this.getJavaPageOptions({ sort: ['dairy.ident,asc'] }),
        term: Term.stringify(this.term),
        labelBundle: useSearchLabelAffiliationStore().labelBundle,
        minValidFrom: useSearchLabelAffiliationStore().minValidFrom,
        maxValidUntil: useSearchLabelAffiliationStore().maxValidUntil,
        dueDate: useSearchLabelAffiliationStore().dueDate
      }
    }
  },
  components: {
    searchDairy,
    searchContact,
    searchLegalEntity,
    searchLabelAffiliation
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.queryParams
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    addFirstLabelOrderForDairy() {
      this.$router
        .push({
          name: 'label_orders_edit',
          params: {
            dairyIdent: useSearchDairyStore().Dairy_ident
          }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    showAddFirstLabel() {
      if (useSearchDairyStore().Dairy_ident && this.items.length == 0 && this.$route.query.size) {
        return true
      } else {
        return false
      }
    },

    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/labelOrders', {
          params: this.queryParams
        })
        const items = response.data
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-contact'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-label-affiliation'].reset()
    },
    back() {
      this.$router.go(-1)
    },
    async download() {
      this.loadingDownload = true
      try {
        const response = await this.axios.get(apiURL + '/labelOrders', {
          params: {
            ...this.getJavaPageOptions(),
            term: Term.stringify(this.term)
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'labelDatenFreigaben.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownload = false
      }
    },
    $formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.size) this.load()
    })
  }
})
</script>
